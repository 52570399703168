<template>
  <lazy-component class="resortSearchPanal">
    <div class="resortSearchMain">
      <div class="resortSearchMainDiv">
        <div class="resortSearchChildDiv">
          <div class="homeSearchMain">
            <img
              class="resortSearchListBackImg"
              src="../assets/images/back_button.png"
              @click="backSearch"
            />
            <!-- <div v-if="$mq === 'sm' || $mq === 'ssm'">
              <div class="resortSearchMainTopMobileDiv">
                <div class="resortSearchMainTopMobile1">
                    <div class="resortSearchMainTopMobileSub1" @click="gotoHotelSearchClick(true)">Search</div>
                    <img style="margin-right: auto;margin: auto;margin-left:31px" src="../assets/images/icon_search_white.png" alt="" width="29px">
                </div>
                <div class="resortSearchMainTopMobile2" 
                    v-bind:style="[showSearchFilterMobile ? {'border': '2px solid #CEB39A', 'background-color':'#00C2CB'} : {}]"
                    @click="clickSearchFilterMobile">
                    <img style="margin: auto;" src="../assets/images/icon_filter.png" alt="" width="28px">
                </div>
              </div>
            </div> -->
            <div class="resortSearchChildDiv1">
              <div class="resortSearchMainSub4">
                <div class="dateSelectHotelDiv">
                  <div style="position: relative">
                    <a-select
                      v-model="selHotelInfo"
                      v-show="hotelShowList.length > 0"
                      ref="pickerResorts"
                      :open="pickerResortsOpen"
                      :filter-option="filterOption"
                      @change="handleHotelNameChange"
                      @blur="onBlurHotel"
                      @dropdownVisibleChange="dropdownHotelChange"
                      style="width: 100%"
                      mode="tags"
                      placeholder="View All or Resort Name"
                    >
                      <a-select-option
                        v-for="_item in hotelShowList"
                        :key="_item._id"
                        :value="_item._id"
                      >
                        <div style="display: flex">
                          <span class="dateSelectHotelCell font-new">{{
                            _item.hotelname
                          }}</span>
                        </div>
                      </a-select-option>
                    </a-select>
                    <div class="dateSelectHotelIconDiv">
                      <a-icon
                        class="dateSelectHotelIconCell"
                        type="down"
                        :style="
                          isResortDropdown
                            ? {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(180deg)',
                              }
                            : {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(0deg)',
                              }
                        "
                      />
                    </div>
                  </div>
                  <!-- <div class="homeSelectText" @click="openHotelSelector">Select</div> -->
                </div>
                <div class="resortVerticalLine"></div>
                <span class="dateSearchDiv">
                  <div class="dateSearchDivMain">
                    <div class="dateSearchSubDiv">
                      <date-range-picker
                        class="dataRangePicker"
                        ref="pickerMain"
                        :opens="'center'"
                        :locale-data="{
                          firstDay: 0,
                          format: 'dd-mm-yyyy HH:mm:ss',
                        }"
                        :minDate="minDate"
                        :maxDate="maxDate"
                        :singleDatePicker="false"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        :ranges="false"
                        :closeOnEsc="false"
                        v-model="dateRange"
                        :date-format="dateFormat"
                        @update="updateValues"
                        @start-selection="startSelection"
                        @finish-selection="finishSelection"
                        @toggle="dateToggle"
                        :linkedCalendars="true"
                      >
                        <template v-slot:input="picker">
                          <div @click="OnClickPicker">
                            {{
                              `${
                                picker.startDate != null
                                  ? moment(picker.startDate).format(
                                      "ddd MMM DD YYYY"
                                    )
                                  : "Check in "
                              } - 
                            ${
                              picker.endDate != null
                                ? moment(picker.endDate).format(
                                    "ddd MMM DD YYYY"
                                  )
                                : "Check out "
                            }`
                            }}
                          </div>
                        </template>
                      </date-range-picker>
                    </div>
                    <div class="dateSelectHotelIconDiv">
                      <a-icon
                        class="dateSelectHotelIconCell"
                        type="down"
                        :style="
                          isDateDropdown
                            ? {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(180deg)',
                              }
                            : {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(0deg)',
                              }
                        "
                      />
                    </div>
                  </div>

                  <!-- <div class="homeSelectText" @click="openDateSelector(true)">Select</div> -->
                </span>
                <div class="resortVerticalLine"></div>
                <div class="homeCountPickerDiv">
                  <div class="homeCountPickerSubDiv">
                    <!-- <a-popover v-model="visibleRoomList" v-if="searchRoomInfo.length > 0" title="Room List" trigger="click" placement="bottom">
                      <template slot="content">
                        <div style="gap: 5px;display: grid;"> 
                          <div v-for="(item, index) in searchRoomInfo" :key="index" style="border: 1px solid #EEEEEE;padding:3px;padding-left: 10px;padding-right: 10px;display: flex;">
                            <span @click="editSearchRoomInfo(index)" style="margin-top:auto;cursor:pointer;margin-bottom:auto;width:100%;color:#787878;margin-right: 10px;">Room {{index + 1}}: {{item.searchAdultCount}} adults, {{item.searchChildCount}} children</span> 
                            <a-icon style="margin: auto;" type="close" @click="removeSearchRoomInfo(index)" class="minusRowBtn" :size="'small'"></a-icon>
                          </div>
                        </div>
                      </template> -->
                    <div
                      class="homeCountPickerSubDiv1"
                      @click="showSearchCountPicker(true)"
                    >
                      <!-- <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" /> -->
                      <span class="homeCountPickerSubText font-new"
                        >{{ searchRoomInfo.length }} rooms,
                        {{
                          searchRoomInfo.reduce(
                            (accumulator, current) =>
                              accumulator + current.searchAdultCount,
                            0
                          )
                        }}
                        adults,
                        {{
                          searchRoomInfo.reduce(
                            (accumulator, current) =>
                              accumulator + current.searchChildCount,
                            0
                          )
                        }}
                        children</span
                      >
                    </div>
                    <!-- </a-popover>
                    <div v-else style="display:flex;cursor:pointer;width:100%">
                      <a-icon style="margin-top:auto;margin-bottom:auto;margin-left:10px;color:#787878" type="user" />
                      <span style="margin-top:auto;margin-bottom:auto;width:100%;margin-left:10px;color:#787878">0 rooms, 0 adults, 0 children</span> 
                    </div> -->
                    <div
                      v-if="false"
                      style="
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-right: 0;
                        width: 250px;
                        cursor: pointer;
                        color: var(--title-color);
                      "
                      @click="showSearchCountPicker(true)"
                    >
                      + Add Another Room
                    </div>

                    <div class="dateSelectHotelIconDiv">
                      <a-icon
                        class="dateSelectHotelIconCell"
                        type="down"
                        :style="
                          isCountDropdown
                            ? {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(180deg)',
                              }
                            : {
                                fontSize: '20px',
                                color: '#787878',
                                transform: 'rotate(0deg)',
                              }
                        "
                      />
                    </div>
                  </div>
                  <!-- <div class="homeSelectText" @click="showSearchCountPicker(true)">Select</div> -->
                </div>
                <div class="resortVerticalLine"></div>
                <div
                  class="homeSearchBtnMobile"
                  @click="gotoHotelSearchClick(true)"
                >
                  <div style="display: flex; margin: auto">
                    <div class="homeSearchBtnText font-new">SEARCH</div>
                    <img
                      class="homeSearchBtnIcon"
                      src="../assets/images/icon_search.png"
                      alt=""
                      width="29px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <a-modal v-model="visibleSearchCountPicker" title="" :footer="null">
              <person-count-selector-multi
                v-if="visibleSearchCountPicker"
                :arr="searchRoomInfo"
                @getCountInfos="getCountInfos"
              ></person-count-selector-multi>
            </a-modal>
            <a-modal
              v-model="visibleSearchCountPickerLast"
              title=""
              :footer="null"
            >
              <person-count-selector-multi
                v-if="visibleSearchCountPickerLast"
                :isSearchClick="true"
                :arr="searchRoomInfo"
                @getCountInfos="getCountInfosSearch"
              ></person-count-selector-multi>
            </a-modal>
          </div>
        </div>
      </div>

      <div v-if="false">
        <div style="display: flex; margin-left: 20px; margin-right: 20px">
          <div style="width: 50%; padding: 10px; border: 1px solid #787878">
            Request
          </div>
          <div style="width: 50%; padding: 10px; border: 1px solid #787878">
            Response
          </div>
        </div>
        <div style="display: flex; margin-left: 20px; margin-right: 20px">
          <div style="width: 50%; padding: 10px; border: 1px solid #787878">
            <pre>{{ requestStr }}</pre>
          </div>
          <div style="width: 50%; padding: 10px; border: 1px solid #787878">
            <pre>{{ responseStr }}</pre>
          </div>
        </div>
      </div>
      <div class="resrotFilterMainDiv" v-if="!isSelectedHotel && !isOnlyOne">
        <div class="resrotFilterSubDiv">
          <div class="resortsFilterLabel" style="margin-left: 0px">
            Find Your Perfect Resort:
          </div>
          <div class="resrotFilterParentDiv">
            <div class="resrotFilterChildDiv">
              <a-select
                class="resortSelectTravel"
                v-model="filterBestFor"
                @change="handleFilterChangeTravel"
                :filter-option="filterOption"
                @blur="onBlurTravel"
                @dropdownVisibleChange="dropdownVisibleChange"
                mode="tags"
                placeholder="Type of travel "
                :size="'large'"
              >
                <a-select-option
                  v-for="_item in bestFor"
                  :key="_item"
                  :value="_item"
                >
                  <div style="display: flex">
                    <span class="selectedCellResortSearch">{{ _item }}</span>
                  </div>
                </a-select-option>
              </a-select>
              <a-icon
                class="resortSearchTravelIcon"
                type="down"
                :style="
                  isDropdown
                    ? {
                        fontSize: '16px',
                        color: '#AAAAAA',
                        transform: 'rotate(180deg)',
                      }
                    : {
                        fontSize: '16px',
                        color: '#AAAAAA',
                        transform: 'rotate(0deg)',
                      }
                "
              />
            </div>
            <div class="resrotLineSpace"></div>
            <div class="resrotFilterChildDiv">
              <a-select
                class="resortSearchSelectMain"
                v-model="filterResortStyle"
                @change="handleFilterChange"
                :size="'large'"
              >
                <a-icon
                  slot="suffixIcon"
                  type="down"
                  :style="{ fontSize: '16px', color: '#AAAAAA' }"
                />
                <a-select-option
                  v-for="(_style, index) in resortStyle"
                  :key="_style"
                  :value="index"
                >
                  <div style="display: flex">
                    <!-- <a-icon style="margin-top:auto;margin-bottom:auto;" type="bank" theme="filled" :style="{ fontSize: '16px' }" /> -->
                    <span class="selectedCellResortSearch">{{ _style }}</span>
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <div class="resrotLineSpace"></div>
            <div class="resrotFilterChildDiv">
              <a-select
                class="resortSearchSelectMain"
                v-model="filterResortBudget"
                @change="handleFilterChange"
                :size="'large'"
              >
                <a-icon
                  slot="suffixIcon"
                  type="down"
                  :style="{ fontSize: '16px', color: '#AAAAAA' }"
                />
                <a-select-option
                  v-for="(_item, index) in budget"
                  :key="_item"
                  :value="index"
                >
                  <div style="display: flex">
                    <span class="selectedCellResortSearch">{{ _item }}</span>
                  </div>
                </a-select-option>
              </a-select>
            </div>
            <div class="resrotLineSpace"></div>
            <div class="resrotFilterChildDiv">
              <a-select
                class="resortSearchSelectMain"
                v-model="filterResortSize"
                @change="handleFilterChange"
                :size="'large'"
              >
                <a-icon
                  slot="suffixIcon"
                  type="down"
                  :style="{ fontSize: '16px', color: '#AAAAAA' }"
                />
                <a-select-option
                  v-for="(_size, index) in resortSizes"
                  :key="_size"
                  :value="index"
                >
                  <div style="display: flex">
                    <!-- <a-icon style="margin-top:auto;margin-bottom:auto;" type="tag" theme="filled" :style="{ fontSize: '16px' }" /> -->
                    <span class="selectedCellResortSearch">{{ _size }}</span>
                  </div>
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>
      <div class="resortTab">
        <search-room-tab-cell
          v-if="!isSelectedHotel"
          @seleteOneHotel="seleteOneHotel"
          @getMoreList="getMoreList"
          :filterResult="filterResult"
          :filtering="filtering"
          :loading="loading"
          :isLast="isLast"
          :searchStartDate="searchStartDateUse"
          :searchEndDate="searchEndDateUse"
          :searchRoomInfo="searchRoomInfoUse"
        ></search-room-tab-cell>
        <div v-else class="resortSearchListDiv" :key="filterResult.length">
          <a-tabs
            v-model="activeKey"
            type="card"
            @change="changeRoomTab"
            @tabClick="clickRoomTab"
            :size="'large'"
            class="resortSearchListTabsDiv"
          >
            <a-tab-pane
              v-for="(pane, index) in searchRoomInfoUse"
              :key="index"
              :closable="false"
            >
              <span slot="tab">
                <div>
                  {{ "Room " + (index + 1) }}
                </div>
              </span>
              <hotel-select-room
                @selectOneRoomSearch="selectOneRoomSearch"
                :roomInfoList="getRoomInfoList(selectedHotelId, index)"
                :searchStartDate="searchStartDateUse"
                :searchEndDate="searchEndDateUse"
                :searchRoomInfoID="index"
                :searchRoomInfo="searchRoomInfoUse"
              ></hotel-select-room>
            </a-tab-pane>
            <a-tab-pane
              v-if="
                searchRoomInfoUse.length > 0 &&
                checkableAddOns(searchRoomInfoUse.length)
              "
              :key="searchRoomInfoUse.length"
              :closable="false"
            >
              <span slot="tab">
                <div>
                  {{ "Add ons" }}
                </div>
              </span>
              <hotel-enhance-part
                :key="isRefreshEnhance"
                :searchStartDate="searchStartDateUse"
                :searchEndDate="searchEndDateUse"
                :selectedRoomAllInfo="searchSelectInfo"
              ></hotel-enhance-part>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <contactus-viewer></contactus-viewer>
      <instagram-viewer style="padding-bottom: 20px"></instagram-viewer>
    </div>
  </lazy-component>
</template>
<script>
import { api } from "../helpers/Helpers";
// import PersonCountSelector from './SearchDialogs/PersonCountSelector.vue';
import moment from "moment";
import SearchRoomTabCell from "./ListItems/search-room-tab-cell.vue";
import HotelSelectRoom from "./ListItems/hotel-select-room.vue";
import HotelEnhancePart from "./ListItems/hotel-enhance-part.vue";
import PersonCountSelectorMulti from "./SearchDialogs/PersonCountSelectorMulti.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ContactusViewer from "./ListItems/contactus-viewer.vue";
import InstagramViewer from "./ListItems/instagram-viewer.vue";
import { nextTick } from 'vue';

const resortSizes = [
  "Resort size",
  "Intimate (less < 50 rooms)",
  "Moderate (more > 50 rooms)",
  "Extensive (more > 100 rooms)",
];
const resortStyle = [
  "Resort style",
  // 'Modern',
  // 'Barefoot luxury',
  // 'Rustic chic',
  // 'Ultra-luxury',
  // 'Elegant',
  // 'Maldivian & Modern',
  // 'Maldivian rustic',
];
const bestFor = [
  // 'Best For',
  // 'Romance',
  // 'Family',
  // 'Friends getaway',
  // 'Solo traveler',
  // 'Foodies',
  // 'Spa & wellness',
  // 'Wellness retreat',
  // 'All-inclusive',
  // 'Best for diving',
  // 'Great house reef',
  // 'Snorkeling',
  // 'Quick airport transfer',
  // 'Underwater dining',
  // 'Overwater villas only',
  // 'Adults-only resort',
  // 'Best for surfing',
  // 'Remote and secluded',
];
const budget = [
  "Budget per night",
  "$500 - $1500",
  "$1500 - $2500",
  "$2500 - $3500",
  "$3500 +",
];
const hotelList = [];
const searchedRooms = [
  { title: "Tab 1", content: "Content of Tab 1", key: "1" },
  { title: "Tab 2", content: "Content of Tab 2", key: "2" },
  { title: "Tab 3", content: "Content of Tab 3", key: "3" },
];

export default {
  components: {
    // PersonCountSelector,
    
    HotelSelectRoom,
    HotelEnhancePart,
    PersonCountSelectorMulti,
    DateRangePicker,
    ContactusViewer,
    InstagramViewer,
    SearchRoomTabCell,
  },
  data() {
    return {
      resortSizes,

      resortStyle,
      bestFor,
      budget,
      searchedRooms,
      activeKey: 0,

      filterResortBudget: 0,
      filterResortSize: 0,
      filterResortStyle: 0,
      filterBestFor: [],
      hotelList,
      hotelShowList: [],

      isDropdown: false,

      filterResult: [],
      searchResult: [],
      searchResultList: [],
      searchRoomCount: [],

      selectedHotelIndex: 0,
      selectedHotelId: "",
      isSelectedHotel: false,

      isOnlyOne: false,

      searchSelectInfo: [],

      visibleSearchCountPicker: false,
      visibleSearchCountPickerLast: false,

      searchRoomInfo: [],
      searchRoomInfoUse: [],
      searchRoomInfoOld: [],
      searchRoomInfoID: 0,

      selHotelInfo: [],

      roomPriceInfo: [],

      searchStartDate: null,
      searchEndDate: null,
      searchStartDateUse: null,
      searchEndDateUse: null,

      visibleRoomList: false,
      visibleRoomListMobile: false,

      currency: 1.0,
      currencyName: "USD",
      currencySign: "$",

      isRefreshEnhance: false,

      loading: true,
      filtering: false,

      isLast: false,
      getIndex: 0,
      itwxSearchRoomInfoList: [],
      diffDays: 0,

      requestStr: "",
      responseStr: "",

      // dateFormat : (val) => {
      //   return moment(val).format('ddd MMM DD YYYY')// HH:mm:ss')
      // },

      dateRange: {
        startDate: null,
        endDate: null,
      },

      minDate: new Date(),
      maxDate: null,

      isOnlyHotel: false,

      isResortDropdown: false,
      isDateDropdown: false,
      isCountDropdown: false,

      showSearchFilterMobile: true,

      pickerResortsOpen: false,

      refreshingList: false,
    };
  },
  created() {
    (this.currency = this.$store.state.selectedCurrency.rate),
      (this.currencyName = this.$store.state.selectedCurrency.name),
      (this.currencySign = this.$store.state.selectedCurrency.sign);
    this.isOnlyHotel = false;
    if (this.$route.query.isOnlyHotel != undefined)
      this.isOnlyHotel = this.$route.query.isOnlyHotel;

    this.searchStartDate = Number(this.$route.query.searchStartDate);
    this.searchEndDate = Number(this.$route.query.searchEndDate);
    this.searchStartDateUse = Number(this.$route.query.searchStartDate);
    this.searchEndDateUse = Number(this.$route.query.searchEndDate);

    this.dateRange.startDate = new Date(this.searchStartDate);
    this.dateRange.endDate = new Date(this.searchEndDate);

    this.searchRoomInfo = JSON.parse(this.$route.query.searchRoomInfo);
    this.selHotelInfo = JSON.parse(this.$route.query.selHotelInfo);

    this.selectedHotelId = this.$route.query.selectedHotelId;
    if (this.selectedHotelId != undefined && this.selectedHotelId != "")
      this.isSelectedHotel = true;
    // else if (this.isOnlyHotel)
    //   this.isSelectedHotel = true;
    else this.isSelectedHotel = false;

    if (this.$store.state.loginUser !== null) {
      const loginType = this.$store.state.loginUser.loginType;
      this.permissionLevel =
        this.$store.state.loginUser[loginType].permissionLevel;
      this.customerId = this.$store.state.loginUser[loginType].customerId;
    }

    // this.isSelectedHotel = false;
    window.scrollTo(0, 0);
  },
  beforeMount() {
    this.refreshingList = false;
    this.getResortStyles();
    this.getResortTypeTravels();
    this.getHotelList();
    window.scrollTo(0, 0);
  },
  methods: {
    moment,

    getResortStyles: async function () {
      const res = await api.getResortStyleInfo();
      if (res.length > 0) {
        this.resortStyle = ["Resort style"];
        for (let i = 0; i < res[0].datalist.length; i++) {
          this.resortStyle.push(res[0].datalist[i].name);
        }
      }
    },
    getResortTypeTravels: async function () {
      const res = await api.getResortTypeTravelInfo();
      if (res.length > 0) {
        this.bestFor = [];
        for (let i = 0; i < res[0].datalist.length; i++) {
          this.bestFor.push(res[0].datalist[i].name);
        }
      }
    },

    dropdownHotelChange(_isDropdown) {
      this.isResortDropdown = _isDropdown;
      this.pickerResortsOpen = _isDropdown;
    },

    openHotelSelector() {
      this.$refs.pickerResorts.blur();
      this.pickerResortsOpen = true;
      this.isResortDropdown = true;
      setTimeout(() => {
        this.$refs.pickerResorts.focus();
      }, 50);
    },

    filterOptionTravel(input, option) {
      const bestForInfo = this.bestFor.filter((obj) => obj == option.key);
      if (bestForInfo.length > 0) {
        return bestForInfo[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return false;
    },
    onBlurTravel() {
      for (let i = 0; i < this.filterBestFor.length; i++) {
        const bestForInfo = this.bestFor.filter(
          (obj) => obj == this.filterBestFor[i]
        );
        if (bestForInfo.length <= 0) {
          this.filterBestFor.splice(i, 1);
        }
      }
    },
    handleFilterChangeTravel() {
      for (let i = 0; i < this.filterBestFor.length; i++) {
        const bestForInfo = this.bestFor.filter(
          (obj) => obj == this.filterBestFor[i]
        );
        if (bestForInfo.length <= 0) {
          this.filterBestFor.splice(i, 1);
        }
      }
      this.handleFilterChange();
    },
    updateValues(aDate) {
      this.searchStartDate = aDate.startDate.getTime(); //moment(aDate.startDate).format("YYYY-MM-DD");
      this.searchEndDate = aDate.endDate.getTime(); //moment(aDate.endDate).format("YYYY-MM-DD");
    },
    startSelection(aDate) {
      this.selectedStartDate = aDate;
    },
    finishSelection(aDate) {
      this.selectedEndDate = aDate;
    },

    dateToggle(dateToggle) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      if (!this.isDateDropdown && !dateToggle) {
        this.$refs.pickerMain.togglePicker(true);
        this.isDateDropdown = true;
      } else {
        this.isDateDropdown = dateToggle;
      }
    },
    OnClickPicker() {},

    clickSearchFilterMobile() {
      this.showSearchFilterMobile = !this.showSearchFilterMobile;
    },
    openDateSelector(isShow) {
      this.$refs.pickerMain.togglePicker(isShow);
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < this.selectedStartDate;
      }
      return classes;
    },
    filterOption(input, option) {
      const hotelInfo = this.hotelShowList.filter(
        (obj) => obj._id == option.key
      );
      if (hotelInfo.length > 0) {
        return (
          hotelInfo[0].hotelname.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }
      return false;
    },

    onBlurHotel() {
      for (let i = 0; i < this.selHotelInfo.length; i++) {
        const hotelInfo = this.hotelShowList.filter(
          (obj) => obj._id == this.selHotelInfo[i]
        );
        if (hotelInfo.length <= 0) {
          this.selHotelInfo.splice(i, 1);
        }
      }
    },
    dropdownVisibleChange(_isDropdown) {
      this.isDropdown = _isDropdown;
    },

    onEdit(targetKey, action) {
      this[action](targetKey);
    },
    remove(targetKey) {
      let activeKey = this.activeKey;
      let lastIndex;
      this.searchedRooms.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.searchedRooms.filter((pane) => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.searchedRooms = panes;
      this.activeKey = activeKey;
    },

    addZeroes(num) {
      let textNum = num.toString();
      let decimalResult = Number(textNum).toFixed(
        Math.max(textNum.split(".")[1]?.length, 2) || 2
      );
      let thousand = decimalResult
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return thousand;
    },
    getHotelList: async function () {
      this.loading = true;
      const res = await api.getHotelsTotal();

      res.sort(function (a, b) {
        let aValue = a["hotelname"];
        let bValue = b["hotelname"];
        return aValue > bValue ? 1 : bValue > aValue ? -1 : 0;
      });
      this.hotelList = res;
      this.hotelShowList = [
        {
          _id: "default",
          hotelname: "View All",
        },
      ];
      res.forEach((item) => {
        if (!item.hotelHidden) {
          this.hotelShowList.push(item);
        }
      });
      // this.hotelShowList = this.hotelShowList.concat(res);
      // this.searchResult = [];
      // this.searchResult.sort(this.compare);
      // this.filterResult = this.searchResult;
      if (this.searchStartDate != null && this.searchEndDate != null) {
        this.refreshingList = true;
        this.onClickSearch();
      }
      // else if (this.hotelList.length > 0){
      //   for(let i = 0; i < this.hotelList.length; i++){
      //     var _temp = {};
      //     _temp['info'] = this.hotelList[i]
      //     this.searchResult.push(_temp);
      //   }
      // }
    },

    handleFilterChange() {
      this.filtering = true;
      for (let i = 0; i < this.searchRoomInfo.length; i++) {
        this.filterResult = this.searchResult;
        if (this.filterResortBudget == 1) {
          this.filterResult = this.filterResult.filter(
            (obj) => obj.mainprice > 0 && obj.mainprice / this.diffDays <= 1500
          );
        } else if (this.filterResortBudget == 2) {
          this.filterResult = this.filterResult.filter(
            (obj) =>
              obj.mainprice / this.diffDays > 1500 &&
              obj.mainprice / this.diffDays <= 2500
          );
        } else if (this.filterResortBudget == 3) {
          this.filterResult = this.filterResult.filter(
            (obj) =>
              obj.mainprice / this.diffDays > 2500 &&
              obj.mainprice / this.diffDays <= 3500
          );
        } else if (this.filterResortBudget == 4) {
          this.filterResult = this.filterResult.filter(
            (obj) => obj.mainprice / this.diffDays > 3500
          );
        }

        if (this.filterResortSize == 1) {
          this.filterResult = this.filterResult.filter(
            (obj) => obj.info.hoteldatarooms.numberofroom < 50
          );
        } else if (this.filterResortSize == 2) {
          this.filterResult = this.filterResult.filter(
            (obj) =>
              obj.info.hoteldatarooms.numberofroom > 50 &&
              obj.info.hoteldatarooms.numberofroom < 100
          );
        } else if (this.filterResortSize == 3) {
          this.filterResult = this.filterResult.filter(
            (obj) => obj.info.hoteldatarooms.numberofroom > 100
          );
        }

        if (this.filterResortStyle != 0) {
          this.filterResult = this.filterResult.filter((obj) =>
            obj.info.hoteldatarooms.stype.includes(
              this.resortStyle[this.filterResortStyle]
            )
          );
        }
        // if (this.filterBestFor != 0){
        //    this.filterResult =  this.filterResult.filter(obj => obj.info.hoteldatarooms.typeoftravel.includes(this.filterBestFor))
        // }

        if (this.filterBestFor.length > 0) {
          var intList = this.filterBestFor; //this.getResortBestIntList(this.filterBestFor);
          this.filterResult = this.filterResult.filter((obj) =>
            intList.every((cat) =>
              obj.info.hoteldatarooms.typeoftravel.includes(cat)
            )
          );
        }
      }

      setTimeout(() => {
        this.filtering = false;
      }, 500);
      //	this.cities = cityData[value];
      //	this.secondCity = cityData[value][0];
    },
    calcTransfer(item, searchCount) {
      const diffTimestampOffset = new Date().getTimezoneOffset() * 60;
      // const transferArray = item.info.transfer.filter(
      //   (obj) =>
      //     this.getDays2(moment(obj.condition.transferstart).utc().unix()) <=
      //       this.getDays2(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       ) &&
      //     this.getDays2(moment(obj.condition.transferend).utc().unix()) >=
      //       this.getDays2(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       )
      // );
      const transferArray = item.info.transfer.filter(obj =>
        obj.condition.transferDate.some(date =>
          this.getDays2(moment(date.transferstart).utc().unix()) <=
            this.getDays2(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
          this.getDays2(moment(date.transferend).utc().unix()) >=
            this.getDays2(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
        )
      );

      this.fromDate = new Date(this.searchStartDate);
      this.toDate = new Date(this.searchEndDate);

      this.diffTime = Math.abs(this.toDate - this.fromDate);
      this.selectedDays = Math.round(this.diffTime / (1000 * 60 * 60 * 24));
      if (transferArray.length > 0) {
        if (
          transferArray[0].price != undefined &&
          transferArray[0].price.transfer != undefined &&
          transferArray[0].price.transfer.length > 0
        ) {
          let peradultprice = 0;
          let perchildprice = 0;
          let perinfantprice = 0;
          transferArray.forEach((array) => {
            array.price.transfer.forEach((item) => {
              if (
                this.selectedDays >= item.min &&
                this.selectedDays <= item.max
              ) {
                peradultprice = item.peradultprice;
                perchildprice = item.perchildprice;
                perinfantprice = item.perinfantprice;
              }
            });
          });

          let childPrice = 0;
          searchCount.searchChildAge.forEach((item) => {
            if (item > transferArray[0].condition.childagerangeto) {
              childPrice += peradultprice;
            } else if (item >= transferArray[0].condition.childagerangefrom) {
              childPrice += perchildprice;
            } else {
              childPrice += perinfantprice;
            }
          });
          const transferPrice =
            peradultprice * searchCount.searchAdultCount + childPrice;
          return transferPrice;
        }
      }
      return 0;
    },
    gotoResortDetail(_info) {
      let strSearchRoomInfo = JSON.stringify(this.searchRoomInfoOld);
      this.$router.push({
        path: "/hotelDetail",
        query: {
          id: _info.info._id,
          searchStartDate: this.searchStartDateUse,
          searchEndDate: this.searchEndDateUse,
          searchRoomInfoID: this.activeKey,
          searchRoomInfo: strSearchRoomInfo,
        },
      });
    },
    onChangeSearchDate(date, dateString) {
      this.searchStartDate = dateString[0];
      this.searchEndDate = dateString[1];
    },
    showSearchCountPicker(isShow) {
      this.visibleSearchCountPicker = isShow;
      if (isShow) this.searchRoomInfoID = this.searchRoomInfo.length;
    },
    getCountInfo(_info) {
      this.searchRoomInfoID = _info.searchRoomInfoID;
      this.searchRoomInfo.splice(this.searchRoomInfoID, 1, {
        searchAdultCount: _info.searchAdultCount,
        searchChildCount: _info.searchChildCount,
        searchChildAge: _info.searchChildAge,
      });
      this.visibleSearchCountPicker = false;
    },

    getCountInfos(_infos) {
      this.searchRoomInfo = _infos;
      this.visibleSearchCountPicker = false;
    },
    handleCountCancel() {
      this.visibleSearchCountPicker = false;
    },

    removeSearchRoomInfo(_index) {
      this.searchRoomInfo.splice(_index, 1);
    },
    editSearchRoomInfo(_index) {
      this.visibleSearchCountPicker = true;
      this.searchRoomInfoID = _index;
      this.visibleRoomList = false;
      this.visibleRoomListMobile = false;
    },

    gotoHotelSearchClick(isShow) {
      if (
        this.searchStartDate == null ||
        this.searchEndDate == null ||
        this.searchStartDate == this.searchEndDate
      ) {
        this.$message.error("Please select date range.");
        return;
      }

      if (this.searchRoomInfo.length <= 0) {
        this.visibleSearchCountPickerLast = isShow;
        if (isShow) this.searchRoomInfoID = this.searchRoomInfo.length;
        return;
      }
      this.onClickSearchHome();
    },
    getCountInfosSearch(_infos) {
      this.searchRoomInfo = _infos;
      this.visibleSearchCountPickerLast = false;
      this.onClickSearchHome();
    },

    onClickSearchHome() {
      if (this.refreshingList == true) return;
      this.refreshingList = true;

      this.$store.commit("setBookListInfo", {
        userid: "",
        data: [],
        searchStartDate: "",
        searchEndDate: "",
      });

      // this.selectedHotelId = '';
      if (
        this.selectedHotelId != "" &&
        this.selHotelInfo.includes(this.selectedHotelId)
      ) {
        // this.selectedHotelId = '';
        this.activeKey = 0;
        this.showSearchFilterMobile = true;
        // this.selHotelInfo = [];
        // this.selHotelInfo.push(this.selectedHotelId);
      } else {
        this.selectedHotelId = "";
      }
      this.onClickSearch();
      this.isRefreshEnhance = !this.isRefreshEnhance;
    },
    getMoreList() {
      this.getIndex++;
      this.searchItwxHotelMain();
    },
    onClickSearch() {
      this.getIndex = 0;
      this.isLast = false;
      this.searchResult = [];
      this.filterResult = [];
      this.roomPriceInfo = [];
      this.loading = true;
      if (
        this.searchStartDate == null ||
        this.searchEndDate == null ||
        this.searchStartDate == this.searchEndDate
      ) {
        this.$message.error("Please select date range.");
        return;
      }
      if (this.searchRoomInfo.length <= 0) {
        this.$message.error("Please enter search information.");
        return;
      }
      if (this.searchRoomInfo.length <= 0) {
        this.$message.error("Please enter search information.");
        return;
      }

      const curDate = new Date();
      if (this.searchStartDate < curDate || this.searchEndDate < curDate) {
        this.$message.error("Dates are expired.");

        this.isSelectedHotel = false;
        return;
      }

      if (this.selHotelInfo.length == 1 && this.selHotelInfo[0] != "default") {
        this.isOnlyOne = true;
      } else {
        this.isOnlyOne = false;
      }

      let strSearchInfo = JSON.stringify(this.searchRoomInfo);
      let strSelHotelInfo = JSON.stringify(this.selHotelInfo);
      if (
        this.searchStartDate != this.$route.query.searchStartDate ||
        this.searchEndDate != this.$route.query.searchEndDate ||
        strSearchInfo != this.$route.query.searchRoomInfo ||
        strSelHotelInfo != this.$route.query.selHotelInfo ||
        this.selectedHotelId != this.$route.query.selectedHotelId
      ) {
        this.$router.push({
          path: "/resorts2/",
          query: {
            searchStartDate: this.searchStartDate,
            searchEndDate: this.searchEndDate,
            searchRoomInfo: strSearchInfo,
            selHotelInfo: strSelHotelInfo,
            selectedHotelId: this.selectedHotelId,
          },
        });
      }

      const fromdate = new Date(this.searchStartDate);
      const todate = new Date(this.searchEndDate);
      const diffTime = Math.round(
        Math.abs(todate - fromdate) / (1000 * 60 * 60 * 24)
      );
      //const diffTime = Math.abs(Math.floor(todate / (1000 * 60 * 60 * 24)) - Math.floor(fromdate / (1000 * 60 * 60 * 24)));
      this.diffDays = diffTime;

      // const curDate = new Date();
      const advanceDay = Math.round(
        Math.abs(fromdate - curDate) / (1000 * 60 * 60 * 24)
      );
      this.searchRoomInfoUse = [];
      this.searchRoomInfoOld = [];

      this.searchStartDateUse = this.searchStartDate;
      this.searchEndDateUse = this.searchEndDate;
      this.searchResultList = [];
      this.searchRoomCount = this.searchRoomInfo.length;
      // this.isSelectedHotel = false;

      if (this.selectedHotelId != undefined && this.selectedHotelId != "")
        this.isSelectedHotel = true;
      else this.isSelectedHotel = false;
      this.searchSelectInfo = [];

      this.itwxSearchRoomInfoList = [];

      var nonItwxSelectedHotel = [];
      var itwxSelectedHotel = [];
      // var detailItwxSelectedHotel = [];
      var detailNonItwxSelectedHotel = [];
      for (let i = 0; i < this.selHotelInfo.length; i++) {
        const detailHotelInfo = this.hotelList.filter(
          (obj) => obj._id == this.selHotelInfo[i]
        );
        if (detailHotelInfo.length > 0) {
          // if (detailHotelInfo[0].apiintegrate == true){
          //   detailItwxSelectedHotel.push(detailHotelInfo[0]);
          //   itwxSelectedHotel.push(detailHotelInfo[0].hotelcode)
          // }
          // else{
          detailNonItwxSelectedHotel.push(detailHotelInfo[0]);
          nonItwxSelectedHotel.push(detailHotelInfo[0]._id);
          // }
        }
      }

      for (let i = 0; i < this.searchRoomInfo.length; i++) {
        this.searchRoomInfoUse.push(this.searchRoomInfo[i]);
        this.searchRoomInfoOld.push(this.searchRoomInfo[i]);
        this.searchSelectInfo.push({ searchindex: -1 });
        const searchInfo = {
          fromdate: fromdate,
          todate: todate,
          adultcount: this.searchRoomInfo[i].searchAdultCount,
          childcount: this.searchRoomInfo[i].searchChildCount,
          childages: this.searchRoomInfo[i].searchChildAge,
          number: i,
          selectedHotel: nonItwxSelectedHotel,
        };
        if (
          nonItwxSelectedHotel.length > 0 ||
          (nonItwxSelectedHotel.length <= 0 && itwxSelectedHotel.length <= 0)
        )
          this.getSearchResult(searchInfo, advanceDay, todate, fromdate, i);

        var adultList = [];
        for (
          var aIndex = 0;
          aIndex < this.searchRoomInfo[i].searchAdultCount;
          aIndex++
        ) {
          adultList.push({
            Age: 25,
          });
        }
        var childList = [];
        for (
          var cIndex = 0;
          cIndex < this.searchRoomInfo[i].searchChildCount;
          cIndex++
        ) {
          childList.push({
            Age: this.searchRoomInfo[i].searchChildAge[cIndex],
          });
        }

        this.itwxSearchRoomInfoList.push({
          Adult: adultList,
          Child: childList,
        });
      }

      // this.requestStr = '';
      // this.responseStr = '';
      // if (itwxSelectedHotel.length > 0 || (itwxSelectedHotel.length <= 0 && nonItwxSelectedHotel.length <= 0))
      //   this.searchItwxHotelMain(itwxSelectedHotel);
      // else{
      //   this.loading = false;
      //   this.isLast = true;
      // }
    },
    searchItwxHotelMain(itwxSelectedHotel) {
      this.loading = true;

      var _startDate = moment(this.searchStartDate).format("YYYY-MM-DD");
      var _endDate = moment(this.searchEndDate).format("YYYY-MM-DD");
      var itwxData = {
        fromdate: _startDate.replaceAll("-", ""),
        todate: _endDate.replaceAll("-", ""),
        roominfo: JSON.stringify(this.itwxSearchRoomInfoList),
        page: this.getIndex + 1,
        pagecount: 20,
        selectedHotel: itwxSelectedHotel,
      };
      this.searchItwxHotel(itwxData, this.diffDays);
    },

    searchItwxHotel: async function (itwxData, diffDays) {
      const res = await api.searchItwxHotel(itwxData);
      this.requestStr = JSON.stringify(res.requestInfo, undefined, 2);
      this.responseStr = JSON.stringify(res.responseInfo, undefined, 2);

      if (res.hotelList.length < 20) this.isLast = true;
      // const resRoom = [];//await api.getRoomsTotal();
      this.makeItwxHotelList(res.hotelList, diffDays);
    },
    makeItwxHotelList(_info, diffDays) {
      // var itwxHotelPriceInfo = [];
      var itwxRoomPriceInfo = [];
      for (let i = 0; i < _info.length; i++) {
        const _hotelinfoList = this.hotelList.filter(
          (obj) => obj.hotelcode == _info[i].HotelCode
        );
        var _hotelinfo = undefined;
        if (_hotelinfoList.length > 0) {
          _hotelinfo = _hotelinfoList[0];
        }

        const _roomList = _info[i].RoomTypeDetails.Rooms.Room;

        const resRoom = _info[i].roomInfos;

        let sumMainPrice = 0;
        let sumMaxPrice = 0;
        let mainPriceRoom = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let maxPriceRoom = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let j = 0; j < _roomList.length; j++) {
          const _roominfoList = resRoom.filter(
            (obj) => obj.roomcode == _roomList[j].RoomTypeCode
          );
          if (_roominfoList.length > 0) {
            const _roomlinfo = _roominfoList[0];

            let promotionPrice = [];
            var feePercent = 20;
            if (
              _hotelinfo == undefined ||
              _hotelinfo.itwxHotelFee == undefined ||
              _hotelinfo.itwxHotelFee == 0
            )
              feePercent = 20;
            else feePercent = _hotelinfo.itwxHotelFee;

            // _roomList[j].TotalRate = _roomList[j].TotalRate  * (100 + feePercent) / 100;

            var subPrice = (_roomList[j].TotalRate * (100 + feePercent)) / 100;

            let totalprice = subPrice;
            let greenTax = 0;

            let greenTaxUnit = 6;
            if (
              _hotelinfo.hoteldatageneral != undefined &&
              _hotelinfo.hoteldatageneral.greentax != undefined &&
              _hotelinfo.hoteldatageneral.greentax != null
            )
              greenTaxUnit = _hotelinfo.hoteldatageneral.greentax;

            let adultcount =
              this.itwxSearchRoomInfoList[_roomList[j].RoomNo - 1].Adult.length;
            greenTax = greenTaxUnit * adultcount * diffDays;

            subPrice = subPrice + greenTax;

            let gstUnit = 1.12;
            if (
              _hotelinfo.hoteldatageneral != undefined &&
              _hotelinfo.hoteldatageneral.gst != undefined
            )
              gstUnit = _hotelinfo.hoteldatageneral.gst;

            const tempSubPrice1 = totalprice / gstUnit;
            totalprice = tempSubPrice1;

            let sevieceUnit = 1.1;
            if (
              _hotelinfo.hoteldatageneral != undefined &&
              _hotelinfo.hoteldatageneral.servicecharges != undefined
            )
              sevieceUnit = _hotelinfo.hoteldatageneral.servicecharges;

            let servicecharges = 0;
            const tempSubPrice2 = totalprice / sevieceUnit;
            servicecharges = tempSubPrice2;
            totalprice = tempSubPrice2;

            let commissionUnit = 0.14;
            if (
              _hotelinfo.hoteldatageneral != undefined &&
              _hotelinfo.hoteldatageneral.commission != undefined
            )
              commissionUnit = _hotelinfo.hoteldatageneral.commission;

            let commission = 0;
            const tempSubPrice = totalprice * commissionUnit;
            commission = tempSubPrice;
            totalprice = tempSubPrice;

            promotionPrice.push({
              promotionid: "itwx",
              promotioninfo: {},
              contrackctedPrice: subPrice,
              sellingPrice: subPrice / 1.2,
              hoteltovue: subPrice,
              sellingTotalPrice: subPrice,
              totalprice: subPrice,
              greenTax: greenTax,
              gstService: subPrice - greenTax - servicecharges,
              commission: commission,
              extraAdultCharge: 0,
              extraChildCharge: 0,
            });
            var roomCellPrice = subPrice;
            var number = _roomList[j].RoomNo - 1;

            itwxRoomPriceInfo.push({
              hotelid:
                _hotelinfo == undefined ? _info[i].HotelCode : _hotelinfo._id, //_hotelinfo._id,
              roomid: _roomlinfo._id, //_roomList[j].RoomTypeCode,//_roomlinfo._id,
              number: number,
              mainprice: roomCellPrice,
              maxprice: roomCellPrice,
              promotionprice: this.sortPromotion(promotionPrice),
              hotelinfo:
                _hotelinfo == undefined
                  ? {
                      hotelname: _info[i].HotelName,
                      hotelid: _info[i].HotelCode,
                    }
                  : _hotelinfo, //_hotelinfo,
              roominfo: _roomlinfo,
              // {
              //   roomname:_roomList[j].RoomType,
              //   roomid:_roomList[j].RoomTypeCode,
              // }//_roomlinfo
            });

            if (
              (mainPriceRoom[number] == 0 ||
                mainPriceRoom[number] > roomCellPrice) &&
              roomCellPrice != 0
            )
              mainPriceRoom[number] = roomCellPrice;
            if (
              maxPriceRoom[number] == 0 ||
              maxPriceRoom[number] < roomCellPrice
            )
              maxPriceRoom[number] = roomCellPrice;
          }
        }

        for (let k = 0; k < diffDays; k++) {
          sumMainPrice += mainPriceRoom[k];
          sumMaxPrice += maxPriceRoom[k];
        }
        if (sumMainPrice <= 0 || sumMaxPrice <= 0) continue;

        this.roomPriceInfo = this.roomPriceInfo.concat(itwxRoomPriceInfo);
        // itwxHotelPriceInfo
        this.searchResult.push({
          hotelid:
            _hotelinfo == undefined ? _info[i].HotelCode : _hotelinfo._id,
          mainprice: sumMainPrice,
          maxprice: sumMaxPrice,
          info:
            _hotelinfo == undefined
              ? {
                  hotelname: _info[i].HotelName,
                  hotelid: _info[i].HotelCode,
                }
              : _hotelinfo, //_hotelinfo,
          days: diffDays,
        });
        // }
      }
      // if (itwxHotelPriceInfo.length > 0){
      //   var tempList = this.searchResult.concat(itwxHotelPriceInfo);

      // }
      // tempList.sort(this.compare);
      var tempList = this.searchResult;
      tempList.sort(this.compare);
      this.searchResult = tempList;
      this.filterResult = tempList;
      this.loading = false;
    },

    getSearchResult: async function (
      searchInfo,
      advanceDay,
      todate,
      fromdate,
      index
    ) {
      console.log(searchInfo);
      // const babyCount = searchInfo.childages.filter((age) => age < 2).length;
      // searchInfo.childcount = searchInfo.childcount - babyCount;
      const res = await api.searchResort(searchInfo);
      // this.SearchResult(res, advanceDay, todate, fromdate, index);
      this.getSearchLastResult(res, index);

      this.refreshingList = false;
    },
    getSearchLastResult(res) {
      this.searchResultList = [...this.searchResultList, ...[res]];

      if (this.searchResultList.length >= this.searchRoomCount) {
        // var result = this.searchResultList.reduce((a, b) => a.filter(c => b.includes(c)));
        var result = this.searchResultList.reduce((a, b) =>
          a.filter((c) => b.some((el) => el.hotelid === c.hotelid))
        );
        // result.sort(this.compare);
        if (result.length > 0) this.calculateData(result);
        else {
          this.isSelectedHotel = false;
          this.loading = false;
          this.isLast = true;
        }
      }
      this.$forceUpdate();
    },
    getExtraPriceByDate(_info, _fromdate, _todate) {
      for (let i = 0; i < _info.length; i++) {
        var _cellInfo = _info[i].periodduration;

        let isInPeriod = true;
        for (let dd = _fromdate; dd < _todate; dd.setDate(dd.getDate() + 1)) {
          let isInPeriodCell = false;
          const curDays = Math.floor(dd.getTime() / (1000 * 60 * 60 * 24));

          for (let iPeriod = 0; iPeriod < _cellInfo.length; iPeriod++) {
            isInPeriodCell = false;
            if (
              _cellInfo[iPeriod].startdate != undefined &&
              _cellInfo[iPeriod].enddate != undefined
            ) {
              const searchStart2 = Math.floor(
                new Date(_cellInfo[iPeriod].startdate).getTime() /
                  (1000 * 60 * 60 * 24)
              );
              const searchEnd2 = Math.floor(
                new Date(_cellInfo[iPeriod].enddate).getTime() /
                  (1000 * 60 * 60 * 24)
              );
              if (curDays >= searchStart2 && curDays <= searchEnd2) {
                isInPeriodCell = true;
                break;
              }
            }
          }
          if (!isInPeriodCell) {
            isInPeriod = false;
            break;
          }
        }

        if (isInPeriod) {
          return i;
        }
      }
      return -1;
    },
    getExtraPricesByDate(_info, _fromdate, _todate) {
      //, taxUnit
      var resPrice = 0;
      var resPriceTax = 0;
      for (let dd = _fromdate; dd < _todate; dd.setDate(dd.getDate() + 1)) {
        const curDays = Math.floor(dd.getTime() / (1000 * 60 * 60 * 24));
        var isGetPrice = false;
        var cellPrice = 0;
        // var cellPriceTax = 0;
        for (let i = 0; i < _info.length; i++) {
          var _cellInfo = _info[i].periodduration;
          for (let iPeriod = 0; iPeriod < _cellInfo.length; iPeriod++) {
            if (
              _cellInfo[iPeriod].startdate != undefined &&
              _cellInfo[iPeriod].enddate != undefined
            ) {
              const searchStart2 = Math.floor(
                new Date(_cellInfo[iPeriod].startdate).getTime() /
                  (1000 * 60 * 60 * 24)
              );
              const searchEnd2 = Math.floor(
                new Date(_cellInfo[iPeriod].enddate).getTime() /
                  (1000 * 60 * 60 * 24)
              );
              if (curDays >= searchStart2 && curDays <= searchEnd2) {
                isGetPrice = true;
                if (
                  _info[i].rate.perday != undefined &&
                  _info[i].rate.perday != null
                )
                  cellPrice = _info[i].rate.perday;
                // if (!_info[i].rate.includegreentax)
                //   cellPriceTax += taxUnit;
                break;
              }
            }
          }
          if (isGetPrice) {
            break;
          }
        }
        resPrice += cellPrice;
        // resPriceTax += cellPriceTax;
      }

      return {
        price: resPrice,
        tax: resPriceTax,
      };
    },
    getDays2(_second) {
      return Math.floor(_second / (24 * 60 * 60));
    },
    getDays(_timestamp) {
      return Math.round(_timestamp / (1000 * 60 * 60 * 24));
    },
    calculateData(_list) {
      this.roomPriceInfo = [];
      let hotelPriceInfo = [];
      for (let i = 0; i < _list.length; i++) {
        ///  Hotel list loop
        let sumMainPrice = 0;
        let sumMaxPrice = 0;

        const hotelid = _list[i].hotelid;
        const _hotelinfo = this.hotelList.filter(
          (obj) => obj._id == hotelid
        )[0];
        if (_hotelinfo == undefined) continue;
        if (
          _hotelinfo.hotelHidden != undefined &&
          _hotelinfo.hotelHidden == true
        )
          continue;

        const fromdate = new Date(_list[i].fromdate);
        const todate = new Date(_list[i].todate);

        const diffTime = Math.abs(
          Math.floor(todate / (1000 * 60 * 60 * 24)) -
            Math.floor(fromdate / (1000 * 60 * 60 * 24))
        );
        const diffDays2 = diffTime;

        let greenTaxUnit = 0;
        if (
          _hotelinfo.hoteldatageneral != undefined &&
          _hotelinfo.hoteldatageneral.greentax != undefined &&
          _hotelinfo.hoteldatageneral.greentax != null
        )
          greenTaxUnit = _hotelinfo.hoteldatageneral.greentax;

        for (let j = 0; j < this.searchResultList.length; j++) {
          ///   multi room search loop

          const cellIndex = this.searchResultList[j].findIndex(
            (element) => element.hotelid == _list[i].hotelid
          );
          const roomInfo = this.searchResultList[j][cellIndex].roominfo;
          const adultcount = this.searchResultList[j][cellIndex].adultcount;
          const childcount = this.searchResultList[j][cellIndex].childcount;
          // const childages = this.searchResultList[j][cellIndex].childages;
          const number = this.searchResultList[j][cellIndex].number;
          const priceinfo = this.searchResultList[j][cellIndex].priceinfo;

          const extraPersonInfo = _hotelinfo.extraperson;
          // let extraPrice = 0;
          // let extraChildCharge = 0;

          // for(let _index = 0; _index < childages.length; _index++){
          //   let extraPriceInfo = extraPersonInfo.filter(obj => (obj.agerange.from <= childages[_index] && obj.agerange.to >= childages[_index]))
          //   // let childPrice = 90;

          //   const _fromdate = new Date(_list[i].fromdate);
          //   const _todate = new Date(_list[i].todate);
          //   if (extraPriceInfo.length > 0){
          //     const _priceInfo = this.getExtraPricesByDate(extraPriceInfo, _fromdate, _todate, greenTaxUnit);
          //     extraChildCharge += _priceInfo;
          //   }

          // }

          // extraPrice = extraChildCharge;

          // let extraAdultCharge = 0;
          let extraAdultChargeUnit = 0;
          // let extraAdultChargeTaxUnit = 0;
          // let adultPricePerDay = 0;
          // let includegreentaxAdult = true;

          const _fromdate = new Date(_list[i].fromdate);
          const _todate = new Date(_list[i].todate);

          let extraPriceAdultInfo = extraPersonInfo.filter(
            (obj) => obj.agerange.from <= 25 && obj.agerange.to >= 25
          );
          if (extraPriceAdultInfo.length > 0) {
            const _priceInfo = this.getExtraPricesByDate(
              extraPriceAdultInfo,
              _fromdate,
              _todate
            ); //, greenTaxUnit
            extraAdultChargeUnit += _priceInfo.price;
            // extraAdultChargeTaxUnit += _priceInfo.tax;

            // const _iPriceInfo = this.getExtraPriceByDate(extraPriceAdultInfo, _fromdate, _todate);
            // if (_iPriceInfo >= 0){
            //   adultPricePerDay = extraPriceAdultInfo[_iPriceInfo].rate.perday;
            //   includegreentaxAdult = extraPriceAdultInfo[_iPriceInfo].rate.includegreentax;
            // }
          }
          // extraAdultChargeUnit += adultPricePerDay * diffDays2;
          // if (!includegreentaxAdult){
          //   extraAdultChargeUnit += greenTaxUnit * diffDays2;
          // }

          // extraAdultCharge = extraAdultChargeUnit * adultcount;
          // extraPrice += extraAdultCharge;

          const _promotion = _hotelinfo.promotion;
          const _hoteldatageneral = _hotelinfo.hoteldatageneral;
          const curDate = new Date();
          const advanceDay = Math.floor(
            Math.abs(fromdate - curDate) / (1000 * 60 * 60 * 24)
          );

          let mainPriceRoom = 0;
          let maxPriceRoom = 0;
          for (let k = 0; k < roomInfo.length; k++) {
            //  selected room list loop
            const roomPriceList = priceinfo[roomInfo[k]._id];
            if (roomPriceList == undefined) continue;

            let calcAdultCount = 0;
            let calcMainAdultCount = 0;
            if (
              roomInfo[k].standardmin != undefined &&
              adultcount > roomInfo[k].standardmin
            ) {
              calcAdultCount = adultcount - roomInfo[k].standardmin;
              calcMainAdultCount = roomInfo[k].standardmin;
            } else if (
              roomInfo[k].standardmin != undefined &&
              adultcount < roomInfo[k].standardmin
            ) {
              let _diffCount = roomInfo[k].standardmin - adultcount;
              if (_diffCount < childcount)
                calcMainAdultCount = roomInfo[k].standardmin;
              else calcMainAdultCount = adultcount + childcount;
            } else {
              calcMainAdultCount = adultcount;
            }

            let extraAdultChargeRoom = extraAdultChargeUnit * calcAdultCount;
            // let extraAdultChargeRoomTax = greenTaxUnit * diffDays2 * calcAdultCount;
            // let extraAdultChargeRoomTax = extraAdultChargeTaxUnit * calcAdultCount;
            // let extraPriceRoom = extraPrice + extraAdultChargeRoom;

            let extraChildCharge = 0;
            // let extraChildChargeTax = 0;
            let childages = [];
            childages = childages.concat(
              this.searchResultList[j][cellIndex].childages
            );
            if (
              roomInfo[k].standardmin != undefined &&
              adultcount < roomInfo[k].standardmin &&
              childages.length > 0
            ) {
              let calcChildCount = roomInfo[k].standardmin - adultcount;
              for (let _iChild = 0; _iChild < calcChildCount; _iChild++) {
                const max = Math.max(...childages);
                const _iMax = childages.indexOf(max);
                childages.splice(_iMax, 1);
              }
            }
            for (let _index = 0; _index < childages.length; _index++) {
              let extraPriceInfo = extraPersonInfo.filter(
                (obj) =>
                  obj.agerange.from <= childages[_index] &&
                  obj.agerange.to >= childages[_index]
              );
              // let childPrice = 90;

              const _fromdate = new Date(_list[i].fromdate);
              const _todate = new Date(_list[i].todate);
              if (extraPriceInfo.length > 0) {
                const _priceInfo = this.getExtraPricesByDate(
                  extraPriceInfo,
                  _fromdate,
                  _todate
                ); //, greenTaxUnit
                extraChildCharge += _priceInfo.price;
                // extraChildChargeTax += greenTaxUnit * diffDays2;
                // extraChildChargeTax += _priceInfo.tax;
              }
            }

            //  + extraChildChargeTax + extraAdultChargeRoomTax

            let emptyPriceInfo = roomPriceList.filter((obj) => obj <= 0);
            if (emptyPriceInfo.length > 0) {
              for (let _index = 0; _index < roomPriceList.length; _index++) {
                roomPriceList[_index] = 0;
              }
            }

            let onePriceObj = 0;
            roomPriceList.forEach((element) => {
              onePriceObj += element;
            });

            let subPrice = onePriceObj;
            let mainPricePro = 0;
            let maxPricePro = 0;
            let promotionPrice = [];

            if (_promotion.length > 0) {
              for (
                let _indexPro = 0;
                _indexPro < _promotion.length;
                _indexPro++
              ) {
                let extraPriceRoom = extraChildCharge + extraAdultChargeRoom;
                if (_promotion[_indexPro].room.includes(roomInfo[k]._id))
                  continue;

                const blackPeriods = _promotion[_indexPro].blackperiod;
                const searchStart1 = new Date(
                  this.searchStartDateUse
                ).getTime();
                const searchEnd1 = new Date(this.searchEndDateUse).getTime();

                let isInBlack = false;
                for (let iBlack = 0; iBlack < blackPeriods.length; iBlack++) {
                  const searchStartDays1 = this.getDays(searchStart1);
                  const searchEndDays1 = this.getDays(searchEnd1);
                  const searchStartDays2 = this.getDays(
                    new Date(blackPeriods[iBlack].blackperiodbegin).getTime()
                  );
                  const searchEndDays2 = this.getDays(
                    new Date(blackPeriods[iBlack].blackperiodend).getTime()
                  );
                  if (
                    searchStartDays1 <= searchEndDays2 &&
                    searchStartDays2 < searchEndDays1
                  ) {
                    isInBlack = true;
                    break;
                  }
                }
                if (isInBlack) continue;

                //////////////////////////////

                const bookingPeriods = _promotion[_indexPro].bookingperiod;

                let isInBooking = false;
                const currentDate = new Date();
                currentDate.setHours(0);
                currentDate.setMinutes(0);
                currentDate.setSeconds(0);
                currentDate.setMilliseconds(0);
                if (bookingPeriods != undefined && bookingPeriods.length > 0) {
                  for (
                    let iBooking = 0;
                    iBooking < bookingPeriods.length;
                    iBooking++
                  ) {
                    const searchStart2 = new Date(
                      bookingPeriods[iBooking].bookingperiodbegin
                    );
                    const searchEnd2 = new Date(
                      bookingPeriods[iBooking].bookingperiodend
                    );
                    searchEnd2.setHours(0);
                    searchEnd2.setMinutes(0);
                    searchEnd2.setSeconds(0);
                    searchEnd2.setMilliseconds(0);
                    if (
                      currentDate >= searchStart2 &&
                      currentDate <= searchEnd2
                    ) {
                      isInBooking = true;
                      break;
                    }
                  }
                } else {
                  isInBooking = true;
                }
                if (!isInBooking) continue;

                ////////////////////////////////

                const travelPeriods = _promotion[_indexPro].travelperiod;

                let isInTravel = true;
                if (travelPeriods != undefined && travelPeriods.length > 0) {
                  const searchStartDate1 = new Date(this.searchStartDateUse);
                  const searchEndDate1 = new Date(this.searchEndDateUse);
                  for (
                    var dd = searchStartDate1;
                    dd < searchEndDate1;
                    dd.setDate(dd.getDate() + 1)
                  ) {
                    let isInTravelCell = false;
                    const curDays = Math.floor(
                      dd.getTime() / (1000 * 60 * 60 * 24)
                    );

                    for (
                      let iTravel = 0;
                      iTravel < travelPeriods.length;
                      iTravel++
                    ) {
                      isInTravelCell = false;
                      if (
                        travelPeriods[iTravel].travelperiodbegin != undefined &&
                        travelPeriods[iTravel].travelperiodend != undefined
                      ) {
                        const searchStart2 = Math.floor(
                          new Date(
                            travelPeriods[iTravel].travelperiodbegin
                          ).getTime() /
                            (1000 * 60 * 60 * 24)
                        );
                        const searchEnd2 = Math.floor(
                          new Date(
                            travelPeriods[iTravel].travelperiodend
                          ).getTime() /
                            (1000 * 60 * 60 * 24)
                        );

                        if (searchStart2 <= curDays && searchEnd2 >= curDays) {
                          isInTravelCell = true;
                          break;
                        }
                      }
                    }
                    if (!isInTravelCell) {
                      isInTravel = false;
                      break;
                    }
                  }
                }
                if (!isInTravel) continue;

                subPrice = onePriceObj;

                if (
                  _promotion[_indexPro].minnights != undefined &&
                  _promotion[_indexPro].minnights != 0
                ) {
                  if (diffDays2 < _promotion[_indexPro].minnights) continue;
                }

                if (
                  _promotion[_indexPro].maxnights != undefined &&
                  _promotion[_indexPro].maxnights != 0
                ) {
                  if (diffDays2 > _promotion[_indexPro].maxnights) continue;
                }
                if (
                  _promotion[_indexPro].advance != undefined &&
                  advanceDay < _promotion[_indexPro].advance
                ) {
                  continue;
                }

                if (
                  _promotion[_indexPro].discount != undefined ||
                  _promotion[_indexPro].discountcalculation.length > 0
                ) {
                  // subPrice = subPrice * (1 - _promotion[_indexPro].discount / 100);
                  if (_promotion[_indexPro].discount == undefined)
                    _promotion[_indexPro].discount = 0;
                  if (
                    _promotion[_indexPro].discountcalculation != undefined &&
                    _promotion[_indexPro].discountcalculation.length > 0
                  ) {
                    let onePriceObjResult = 0;
                    let iDate = 0;

                    let haveDiscounts = false;
                    for (
                      var d = new Date(this.searchStartDateUse);
                      d < new Date(this.searchEndDateUse);
                      d.setDate(d.getDate() + 1)
                    ) {
                      let discountCalc =
                        _promotion[_indexPro].discountcalculation;
                      let aDiscount = _promotion[_indexPro].discount;
                      haveDiscounts = false;
                      for (
                        let iDiscount = 0;
                        iDiscount < discountCalc.length;
                        iDiscount++
                      ) {
                        var _days = Math.floor(d.getTime() / 86400000);
                        var daysStart = Math.floor(
                          new Date(
                            discountCalc[iDiscount].discountperiodbegin
                          ).getTime() / 86400000
                        );
                        var daysEnd = Math.floor(
                          new Date(
                            discountCalc[iDiscount].discountperiodend
                          ).getTime() / 86400000
                        );
                        if (
                          _days >= daysStart &&
                          _days <= daysEnd &&
                          discountCalc[iDiscount].discount != undefined
                        ) {
                          aDiscount = discountCalc[iDiscount].discount;
                          haveDiscounts = true;
                          break;
                        }
                      }

                      if (!haveDiscounts) {
                        break;
                      }

                      if (roomPriceList[iDate] == undefined)
                        roomPriceList[iDate] = 0;

                      onePriceObjResult +=
                        roomPriceList[iDate] * (1 - aDiscount / 100);
                      iDate++;
                    }
                    if (!haveDiscounts) {
                      continue;
                    }
                    subPrice = onePriceObjResult;
                  } else {
                    subPrice =
                      subPrice * (1 - _promotion[_indexPro].discount / 100);
                  }
                } else if (
                  _promotion[_indexPro].stayoffer != undefined &&
                  _promotion[_indexPro].stayoffer.stayday != undefined &&
                  _promotion[_indexPro].stayoffer.offerday != undefined &&
                  _promotion[_indexPro].stayoffer.stayday != 0 &&
                  _promotion[_indexPro].stayoffer.offerday != 0
                ) {
                  const _stayday = _promotion[_indexPro].stayoffer.stayday; //7
                  const _offerDay = _promotion[_indexPro].stayoffer.offerday; //5
                  subPrice = 0;

                  for (
                    let iPrice = 0;
                    iPrice < roomPriceList.length;
                    iPrice++
                  ) {
                    if (iPrice % _stayday > _offerDay - 1) continue;
                    subPrice += roomPriceList[iPrice];
                  }
                }
                // else{
                // continue;
                // }

                if (
                  _hoteldatageneral.publishedrate != undefined &&
                  _hoteldatageneral.publishedrate != 0
                ) {
                  subPrice = subPrice * _hoteldatageneral.publishedrate;
                }
                const sellingPrice = subPrice;
                let greenTaxMain = 0;
                greenTaxMain = greenTaxUnit * calcMainAdultCount * diffDays2;
                // let greenTax = 0;
                // greenTax = greenTaxUnit * (adultcount + childcount) * diffDays2
                if (
                  _hoteldatageneral.calculationfomula == "Formula1" &&
                  subPrice != 0
                ) {
                  subPrice = subPrice + greenTaxMain; //greenTax;
                  // extraPriceRoom += extraAdultChargeRoomTax + extraChildChargeTax;
                  // totalprice = subPrice;
                }

                let totalprice = 0;
                if (_hoteldatageneral.calculationfomula == "Formula1") {
                  totalprice = subPrice - greenTaxMain; // - extraAdultChargeRoomTax - extraChildChargeTax;
                } else {
                  totalprice = subPrice - greenTaxMain;
                }
                if (totalprice < 0) totalprice = 0;

                // let extraChildCharge = extraPrice;// * this.searchChildCount;
                if (subPrice != 0) {
                  subPrice = subPrice + extraPriceRoom;
                }
                const sellingTotalPrice = subPrice;
                let hoteltovue = 0;
                hoteltovue = subPrice;
                if (
                  _hoteldatageneral.publishedrate != undefined &&
                  _hoteldatageneral.publishedrate != 0
                ) {
                  hoteltovue = hoteltovue / _hoteldatageneral.publishedrate;
                }

                // let gst = 0;
                if (
                  _hoteldatageneral.gst != undefined &&
                  _hoteldatageneral.gst != 0
                ) {
                  const tempSubPrice = totalprice / _hoteldatageneral.gst;
                  totalprice = tempSubPrice;
                }
                // subPrice = subPrice + gst;
                // let servicecharges = 0;
                if (
                  _hoteldatageneral.servicecharges != undefined &&
                  _hoteldatageneral.servicecharges != 0
                ) {
                  const tempSubPrice =
                    totalprice / _hoteldatageneral.servicecharges;
                  // servicecharges = tempSubPrice;
                  totalprice = tempSubPrice;
                }
                // subPrice = subPrice + servicecharges;
                let gstService =
                  subPrice > 0
                    ? (subPrice - extraPriceRoom - greenTaxMain) * 0.244
                    : 0; // - extraAdultChargeRoomTax - extraChildChargeTax

                let commissionPrice =
                  subPrice > 0
                    ? (subPrice - extraPriceRoom - greenTaxMain) * (1 - 0.244)
                    : 0; // - extraAdultChargeRoomTax - extraChildChargeTax
                let commission = 0;
                if (
                  _hoteldatageneral.commission != undefined &&
                  _hoteldatageneral.commission != 0
                ) {
                  const tempSubPrice =
                    commissionPrice * _hoteldatageneral.commission;
                  commission = tempSubPrice;
                  totalprice = tempSubPrice;
                }

                promotionPrice.push({
                  promotionid: _promotion[_indexPro]._id,
                  promotioninfo: _promotion[_indexPro],
                  contrackctedPrice: onePriceObj,
                  sellingPrice: sellingPrice,
                  hoteltovue: hoteltovue,
                  sellingTotalPrice: sellingTotalPrice,
                  totalprice: subPrice,
                  greenTax: greenTaxMain, //greenTax,//greenTaxUnit * (adultcount + childcount) * diffDays2,
                  gstService: gstService,
                  commission: commission,
                  extraAdultCharge: extraAdultChargeRoom,
                  extraChildCharge: extraChildCharge,
                });

                if (mainPricePro == 0 || mainPricePro > subPrice) {
                  mainPricePro = subPrice;
                }
                if (maxPricePro == 0 || maxPricePro < subPrice)
                  maxPricePro = subPrice;
              }
            } else {
              let extraPriceRoom = extraChildCharge + extraAdultChargeRoom;

              subPrice = onePriceObj;
              if (
                _hoteldatageneral.publishedrate != undefined &&
                _hoteldatageneral.publishedrate != 0
              ) {
                subPrice = subPrice * _hoteldatageneral.publishedrate;
              }

              // let totalprice = subPrice;
              let greenTax = greenTaxUnit * calcMainAdultCount * diffDays2;
              if (
                _hoteldatageneral.calculationfomula == "Formula1" &&
                subPrice != 0
              ) {
                subPrice = subPrice + greenTax;
                // totalprice = subPrice;
              }
              if (subPrice != 0) subPrice = subPrice + extraPriceRoom;

              if (mainPricePro == 0 || mainPricePro > subPrice)
                mainPricePro = subPrice;
              if (maxPricePro == 0 || maxPricePro < subPrice)
                maxPricePro = subPrice;
            }

            this.roomPriceInfo.push({
              hotelid: _list[i].hotelid,
              roomid: roomInfo[k]._id,
              number: number,
              maxprice: maxPricePro,
              mainprice: mainPricePro,
              promotionprice: this.sortPromotion(promotionPrice),
              hotelinfo: _hotelinfo,
              roominfo: roomInfo[k],
            });

            if (
              (mainPriceRoom == 0 || mainPriceRoom > mainPricePro) &&
              mainPricePro != 0
            )
              mainPriceRoom = mainPricePro;
            if (
              (maxPriceRoom == 0 || maxPriceRoom > maxPricePro) &&
              maxPricePro != 0
            )
              maxPriceRoom = maxPricePro;
            // if (maxPriceRoom == 0 || maxPriceRoom < maxPricePro)
            //   maxPriceRoom = maxPricePro;
          }
          sumMainPrice += mainPriceRoom;
          sumMaxPrice += maxPriceRoom;
        }
        hotelPriceInfo.push({
          hotelid: _list[i].hotelid,
          mainprice: sumMainPrice,
          maxprice: sumMaxPrice,
          info: _hotelinfo,
          days: diffDays2,
        });
      }

      // hotelPriceInfo.sort(this.compare);

      var tempList = this.searchResult.concat(hotelPriceInfo);
      let filterBuf = tempList;
      for (let index = 0; index < filterBuf.length; index++) {
        filterBuf[index].mainprice =
          Math.round(
            (filterBuf[index].mainprice
              ? (filterBuf[index].mainprice +
                  this.calcTransfer(
                    filterBuf[index],
                    this.searchRoomInfoUse[0]
                  )) *
                this.currency
              : filterBuf[index].info.hoteldatageneral.reqularprice *
                filterBuf[index].days *
                this.currency) * 100
          ) / 100;
      }
      // tempList = filterBuf.sort((p1, p2) =>
      //   p1.mainprice > p2.mainprice ? 1 : p1.mainprice < p2.mainprice ? -1 : 0
      // );
      tempList = tempList.sort(this.compare);
      tempList = this.moveRequestBase(tempList);

      this.searchResult = tempList;
      this.filterResult = tempList;

      this.loading = false;
      this.isLast = true;
    },
    getRoomInfoList(hotelid, number) {
      var result = this.roomPriceInfo.filter(
        (obj) => obj.hotelid == hotelid && obj.number == number
      );

      var _result = result.sort(this.compare);
      _result = this.moveRequestBase(_result);

      return _result;
    },

    compare(a, b) {
      if (a.mainprice < b.mainprice) {
        return -1;
      }
      if (a.mainprice > b.mainprice) {
        return 1;
      }
      // if (a.mainprice <= 0){
      //   return 1;
      // }
      // if (b.mainprice <= 0){
      //   return 1;
      // }
      return 0;
    },

    moveRequestBase(_list) {
      let resultList = [];
      for (let i = _list.length - 1; i >= 0; i--) {
        if (_list[i].mainprice > 0) {
          resultList.unshift(_list[i]);
        } else {
          resultList.push(_list[i]);
        }
      }
      return resultList;
    },

    sortPromotion(_list) {
      var resultList = _list;
      if (resultList.length <= 0) return resultList;
      resultList.sort(this.comparePromotion);
      return resultList;
    },
    comparePromotion(a, b) {
      if (a.totalprice < b.totalprice) {
        return -1;
      }
      if (a.totalprice > b.totalprice) {
        return 1;
      }
      return 0;
    },

    selectOneRoomSearch(index, _info) {
      this.searchSelectInfo.splice(index, 1, _info);
      this.showSearchFilterMobile = false;
      for (let i = 0; i < this.searchRoomInfoUse.length; i++) {
        if (
          this.searchSelectInfo.filter(
            (obj) =>
              obj.searchindex == i &&
              obj.hotelid != undefined &&
              obj.hotelid != ""
          ).length <= 0
        ) {
          this.activeKey = i;
          window.scrollTo(0, 0);
          return;
        }
      }
      this.activeKey = this.searchRoomInfoUse.length;

      window.scrollTo(0, 0);

      this.isRefreshEnhance = !this.isRefreshEnhance; //update enhance
    },
    seleteOneHotel(index) {
      window.scrollTo(0, 0);

      let strSearchInfo = JSON.stringify(this.searchRoomInfo);
      // let strSelHotelInfo = JSON.stringify(this.selHotelInfo)
      let strSelHotelInfo = JSON.stringify([this.filterResult[index].hotelid]);
      if (this.filterResult.length == 1) {
        this.selectedHotelIndex = index;
        this.selectedHotelId = this.filterResult[index].hotelid;
        this.isSelectedHotel = true;
        this.activeKey = 0;
        if (this.selectedHotelId != this.$route.query.selectedHotelId) {
          this.$router.push({
            path: "/resorts2/",
            query: {
              searchStartDate: this.searchStartDate,
              searchEndDate: this.searchEndDate,
              searchRoomInfo: strSearchInfo,
              selHotelInfo: strSelHotelInfo,
              selectedHotelId: this.selectedHotelId,
            },
          });
        }
      } else {
        let routeData = this.$router.resolve({
          path: "/resorts2/",
          query: {
            searchStartDate: this.searchStartDate,
            searchEndDate: this.searchEndDate,
            searchRoomInfo: strSearchInfo,
            selHotelInfo: strSelHotelInfo,
            selectedHotelId: this.filterResult[index].hotelid,
          },
        });
        window.open(routeData.href, "_blank");
      }
    },
    backSearch() {
      if (this.activeKey > 0) {
        this.activeKey -= 1;
      } else {
        this.isSelectedHotel = false;
        this.searchSelectInfo = this.searchSelectInfo.map((obj) => ({
          ...obj,
          hotelid: "",
        }));

        this.selectedHotelId = "";
        let strSearchInfo = JSON.stringify(this.searchRoomInfo);
        let strSelHotelInfo = JSON.stringify(this.selHotelInfo);
        if (this.selectedHotelId != this.$route.query.selectedHotelId) {
          this.$router.push({
            path: "/resorts2/",
            query: {
              searchStartDate: this.searchStartDate,
              searchEndDate: this.searchEndDate,
              searchRoomInfo: strSearchInfo,
              selHotelInfo: strSelHotelInfo,
              selectedHotelId: this.selectedHotelId,
            },
          });
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    changeRoomTab(key) {
      this.isRefreshEnhance = !this.isRefreshEnhance; //update enhance
    },
    clickRoomTab(key) {
      if (key == this.searchRoomInfoUse.length) {
        for (let i = 0; i < this.searchRoomInfoUse.length; i++) {
          if (
            this.searchSelectInfo.filter(
              (obj) =>
                obj.searchindex == i &&
                obj.hotelid != undefined &&
                obj.hotelid != ""
            ).length <= 0
          ) {
            this.$message.info("Please all rooms!");
            this.delaySetActive(i);
            break;
          }
        }
      }
    },

    checkableAddOns(key) {
      if (key == this.searchRoomInfoUse.length) {
        for (let i = 0; i < this.searchRoomInfoUse.length; i++) {
          if (
            this.searchSelectInfo.filter(
              (obj) =>
                obj.searchindex == i &&
                obj.hotelid != undefined &&
                obj.hotelid != ""
            ).length <= 0
          ) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    delaySetActive(index) {
      setTimeout(() => {
        this.activeKey = index;
      }, 10);
    },

    handleHotelNameChange() {
      if (
        this.selHotelInfo.length > 0 &&
        this.selHotelInfo[this.selHotelInfo.length - 1] == "default"
      ) {
        this.selHotelInfo = ["default"];
        return;
      }

      for (let i = 0; i < this.selHotelInfo.length; i++) {
        if (this.selHotelInfo[i] == "default") {
          this.selHotelInfo.splice(i, 1);
        }
        var checkList = this.hotelShowList.filter(
          (obj) => obj._id == this.selHotelInfo[i]
        );
        if (checkList.length <= 0) {
          this.selHotelInfo.splice(i, 1);
        }
      }
    },

    getResortBestForStr(_array) {
      const resortList = {
        0: "Romance",
        1: "Family",
        2: "Friends getaway",
        3: "Solo traveler",
        4: "Foodies",
        5: "Spa & wellness",
        6: "Wellness retreat",
        7: "All-inclusive",
        8: "Best for diving",
        9: "Great house reef",
        10: "Snorkeling",
        11: "Quick airport transfer",
        12: "Underwater dining",
        13: "Overwater villas only",
        14: "Adults-only resort",
        15: "Best for surfing",
        16: "Remote and secluded",
      };
      var _arratStr = [];
      for (let i = 0; i < _array.length; i++) {
        if (_array[i] in resortList) _arratStr.push(resortList[_array[i]]);
      }
      return _arratStr.join(" | ");
    },

    getResortBestIntList(_array) {
      const resortList = {
        Romance: 0,
        Family: 1,
        "Friends getaway": 2,
        "Solo traveler": 3,
        Foodies: 4,
        "Spa & wellness": 5,
        "Wellness retreat": 6,
        "All-inclusive": 7,
        "Best for diving": 8,
        "Great house reef": 9,
        Snorkeling: 10,
        "Quick airport transfer": 11,
        "Underwater dining": 12,
        "Overwater villas only": 13,
        "Adults-only resort": 14,
        "Best for surfing": 15,
        "Remote and secluded": 16,
      };
      var _arratStr = [];
      for (let i = 0; i < _array.length; i++) {
        if (_array[i] in resortList) _arratStr.push(resortList[_array[i]]);
      }
      return _arratStr;
    },
  },
  mounted() {
    nextTick(() => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
.ant-select-selection-selected-value .selectedCell {
  white-space: nowrap !important;
}
</style>
